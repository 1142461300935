// @flow

import React from 'react';
import UCSF from './ucsf-logo-text@2x.png';
import UW from './UW-logo@2x.png';
import VA from './VA-logo@2x.png';
import P4Y from './p4y-logo-0921_pr-5c.png';
import './FooterMP.scss';
import { isMyPath4you } from '../../../lib/appMode';

const isShowingUniversityLogos =
  process.env.REACT_APP_MODE === 'mypathVAStudy' ||
  process.env.REACT_APP_MODE === 'mypath4you' ||
  process.env.REACT_APP_MODE === 'impact';

console.log(isShowingUniversityLogos);

const Footer = (props: { id: string }): React.Element<'footer'> => {
  const classNames = `mbc-footer-mp ${isMyPath4you ? 'path4you' : ''} ${isShowingUniversityLogos ? 'university-logos' : ''}`;

  return (
    <footer className={classNames} {...props}>
      {isMyPath4you && <img className="path4you" src={P4Y} alt="Path4You" />}
      <img className="va" src={VA} alt="US Department of Veterans Affairs" />

      {isShowingUniversityLogos && (
        <>
          <img className="uw" src={UW} alt="University of Washington" />
          <img
            className="ucsf"
            src={UCSF}
            alt="UCSF - University of California, San Francisco"
          />
        </>
      )}
    </footer>
  );
};

export default Footer;
