// @flow

import React from 'react';
import NavButton from '../../shared/nav/NavButton';
import type { PageProps } from '../../../lib/types';
import FooterMP from '../../shared/footers/FooterMP';
import MyReproductiveHealthJourney from './MyReproductiveHealthJourney';
import './SplashMP.scss';
import {
  isMyPathVAStudy,
  isMyPathGeneral,
  isMyPathPeripartum,
} from '../../../lib/appMode';

const isShowingFooter =
  process.env.REACT_APP_MODE !== 'mypathGeneral' &&
  process.env.REACT_APP_MODE !== 'mypathPeripartum';

const SplashMP = ({ lexicon }: PageProps): React.ChildrenArray<*> => (
  <>
    <div className="spacer-1" />
    <div className="flexbox-group">
      <MyReproductiveHealthJourney />
      <div className="locale-buttons-container">
        <NavButton
          str={lexicon.get('MyPathSplash')}
          hiddenText={lexicon.get('hiddenNavText')}
          to={isMyPathVAStudy ? '/en/study_mp' : '/en/intro_mp'}
          tabIndex="0"
        />
        {isMyPathGeneral && !isMyPathPeripartum && (
          <NavButton
            str="en español"
            hiddenText={lexicon.get('hiddenNavText')}
            to="/es/intro_mp"
            tabIndex="0"
          />
        )}
      </div>
    </div>
    <div className="spacer-2" />

    {isShowingFooter && <FooterMP />}
  </>
);

export default SplashMP;
